<template>
    <article v-if="componentInfo.isLoaded" :key="`article_${componentInfo.key}`" :class="componentInfo.class">
        <TitleBox :title="componentInfo.tag" :icon="componentInfo.icon" />
        <div class="mt-4 p-2">
            <ul class="row">
                <li v-for="(item,index) in componentInfo.items" :key="`article_${index}`" class="last-hide border-b border-gray-600 pb-5 mb-5 flex justify-start space-x-2" @click="touchContent(item)">
                    <ArticleItem :content="item" />
                </li>
            </ul>
        </div>
    </article>
    <article :class="componentInfo.class" class="w-full flex flex-col space-y-2" v-else>
        <Skeleton class="w-full h-[84px]" />
        <div class="w-full flex justify-start space-x-2 px-2">
            <Skeleton class="w-[74px] h-[90px]" />
            <div class="w-full flex flex-col space-y-2">
                <Skeleton class="w-[80%] h-[20px]" />
                <Skeleton class="w-[60%] h-[20px]" />
                <Skeleton class="w-[60%] h-[20px]" />
            </div>
        </div>
        <div class="w-full flex justify-start space-x-2 px-2">
            <Skeleton class="w-[74px] h-[90px]" />
            <div class="w-full flex flex-col space-y-2">
                <Skeleton class="w-[80%] h-[20px]" />
                <Skeleton class="w-[60%] h-[20px]" />
                <Skeleton class="w-[60%] h-[20px]" />
            </div>
        </div>
    </article>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { TitleBox } from '../molecules';
    import { ArticleItem } from '../organisms';
    import { Content, ContentRepository } from '@gn2studio/gn2.library.common';
    import config from '@/config';
    import router from '@/router';
    import { Skeleton } from '../atoms';

    interface articleParameter {
        tag:string,
        class?:string|null|undefined,
        icon?:string|null|undefined,
    };

    const property = defineProps<articleParameter>();
    const repository = new ContentRepository(config.apis.article, '');

    var componentInfo = ref({
        key:0,
        isLoaded:false,
        class:'',
        tag:'',
        icon:'',
        items:[] as Content[]
    });

    onMounted(async () => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.tag = property.tag ?? '';
        componentInfo.value.icon = property.icon ?? '';

        await getTagContent(componentInfo.value.tag);

        setTimeout(() => {
            componentInfo.value.isLoaded = true;
        }, 200)
        
    });

    const getTagContent = async (tag:string) => {
        let rst = await repository.GetTagContents(tag, { curPage : 1, pageSize : 5, keyword : "" });
        if (rst.check) {
            componentInfo.value.items = rst.data;
        }
    };

    const touchContent = (content:Content) => {
        router.push(`/content/view/${content.id}`);
    }
</script>

