<template>
<div class="container mx-auto h-screen flex items-center justify-center p-2">
  <div class="loginbox w-full max-w-4xl overflow-hidden flex justify-between">
    <div class="left-box box-content p-8 bg-blue-100 dark:bg-gray-800 rounded-lg shadow-sm w-2/5">
      <div class="w-full block dark:hidden">
        <router-link to="/">
          <img src="/logo/logo.png" width="170" height="32" alt="MerryTokTok" />
        </router-link>
      </div>
      <div class="w-full hidden dark:block">
        <router-link to="/">
          <img src="/logo/logo_white.png" width="170" height="32" alt="MerryTokTok" />
        </router-link>
      </div>

      <div class="text-slate-200 mt-3">
        <h3 class="text-2xl font-bold font-sans">{{ t('site.welcomeMerrytoktok') }}</h3>
        <p class="mt-1 text-gray-600 dark:text-gray-400">
          {{ t('site.welcomeMerrytoktok2') }} {{ t('common.noaccount') }}
          <router-link to="/regist" class="text-blue-500 font-bold">{{ t('system.Signup') }}</router-link>
        </p>
      </div>

      <!-- 이메일 및 비밀번호 입력 -->
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-6">
        <!-- 이메일 입력 -->
        <div class="grid grid-rows-1 px-2">
          <label for="email" class="text-gray-700 dark:text-gray-300 block mb-1">Email</label>
          <GInput type="email" :placeholder="t('common.requiredEmail')" v-model="pageinfo.data.email" class="w-full p-2 border rounded" @keyup.enter="fnNextStep" />
        </div>
        <!-- 비밀번호 입력 -->
        <div class="grid grid-rows-1 px-2">
          <label for="password" class="text-gray-700 dark:text-gray-300 block mb-1">Password</label>
          <GInput id="input_password" type="password" :placeholder="t('system.requiredPassword')" v-model="pageinfo.data.password" class="w-full p-2 border rounded" @keyup.enter="fnLogin" />
        </div>
      </div>

      <!-- 비밀번호 찾기 -->
      <div class="grid grid-cols-1 xl:grid-cols-2 mt-4">
        <div class="flex justify-start items-center">
          <label class="cursor-pointer flex">
            <input type="checkbox" v-model="pageinfo.data.isSave" class="block border-none" />
            <span class="text-gray-600 dark:text-gray-200 ml-1 text-xs mt-0.5">{{ t('system.stayLogin')  }}</span>
          </label>
        </div>
        <div class="text-gray-600 dark:text-gray-300 text-sm text-right">
          <a href="/forgot-password">{{ t('system.forgot') }}</a>
        </div>
      </div>

      <!-- 로그인 버튼 -->
      <div class="w-full mt-4">
        <GButton @click="fnLogin" size="md" class="w-full py-2 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">{{ t('common.moveSignin') }}</GButton>
      </div>
    </div>

    <!-- 오른쪽 박스 -->
    <div class="right-box w-3/5 ml-2 hidden md:block">
      <img
        src="/banners/editor.jpg"
        alt="Banner"
        class="w-full h-auto rounded-md shadow-sm"
      />
    </div>
  </div>
</div>

</template>

<script setup lang="ts">
import { GInput, GButton } from '@/components/atoms';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { HeadSet } from '@/utility';
import { MemberRepository } from '@gn2studio/gn2.library.common';
import { useMemberStore,useGlobalStore } from '@/store';
import config from '@/config';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const auth = new AuthService();
const route = useRoute();
const memberStore = useMemberStore();
const site = useGlobalStore();
const head = new HeadSet();
head.title = 'Login - MerryTokTok';

useHead(head.CreateJson());

var pageinfo = ref({
  key:0,
  isLoaded:false,
  data: {
    email:'',
    password:'',
    isSave:false
  },
  callbackUrl: '/'
});

onMounted(() => {
  if (route.query.backurl !== null && route.query.backurl !== undefined) {
    pageinfo.value.callbackUrl = String(route.query.backurl).trim() ?? '';
  }
  
  setTimeout(() => {
      site.setValue('loading', false);
  }, 100);
});

const fnNextStep = () => {
  document.getElementById('input_password')?.focus();
};

const fnLogin = async () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /\S{6,}/;

  if (pageinfo.value.data.email === "") {
    MessageBox.Alert(t('common.requiredEmail'));
  } else if (emailRegex.test(pageinfo.value.data.email) === false) {
    MessageBox.Alert(t('common.regexEmail'));
  } else if (pageinfo.value.data.password === "") {
    MessageBox.Alert(t('common.requiredPassword'));
  } else if (passwordRegex.test(pageinfo.value.data.password) === false) {
    MessageBox.Alert(t('common.regexPassword'));
  } else {
    site.setValue('loading', true);
    let rst = await auth.apiLogin(pageinfo.value.data.email, pageinfo.value.data.password, pageinfo.value.data.isSave);
    if (rst.check) {
      const accessToken = await auth.getAccessToken();
      const memberRep = new MemberRepository(config.apis.member, accessToken);
      const rst = await memberRep.GetUser();
      if (rst.check) {
        memberStore.setMember(rst.data);
      }
      window.location.href = pageinfo.value.callbackUrl;
    } else {
      MessageBox.Alert(rst.message);
    }
    site.setValue('loading', false);
  }
};

</script>

<style scoped>
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginbox {
  width:100%;
  max-width: 1000px;
  overflow:hidden;
  display:flex;
  justify-content: space-between;
}

.left-box { width:45%; }
.right-box { width:calc(55% - 8px);box-sizing: border-box;padding:0;margin-left:8px; }

@media (max-width: 768px) {
  .left-box { width:100%; }
  .right-box { display:none; }
}
</style>
