<template>
<GContainer :key="`content_list_${pageInfo.key}`" v-if="pageInfo.isLoaded" :class="pageInfo.class">
    <div class="w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3">
        <div class="hidden lg:block w-1/6 mt-5">
            <CustomerMenu :position="0"></CustomerMenu>
        </div>
        <div class="w-full lg:w-5/6">
            <div class="w-full mt-5 text-xl text-gray-500 dark:text-gray-200">
                <h3>{{ t('site.helpmessage') }}</h3>
                <h3><b class="text-gray-700 dark:text-white">{{ t('site.merrytoktok') }} {{ t('site.customer') }}</b>{{t('common.mentend')}}</h3>
            </div>
            <div class="w-full mt-8">
                <h3 class="text-lg font-semibold text-gray-600 dark:text-gray-200">{{ t('common.faq') }}</h3>
                <div class="w-full h-24 flex items-center align-middle bg-slate-200 dark:bg-slate-600 justify-center mt-1 rounded-lg p-4 lg:p-8 space-x-2">
                    <GInput :placeholder="t('common.InputSearchPlaceHolder')" class="border border-gray-200 dark:border-gray-500 rounded-lg w-5/6 shadow-md" size="lg" type="default"></GInput>
                    <DefaultButton class="w-1/6 h-[45px]" size="sm" type="default">{{t('common.ButtonSearch')}}</DefaultButton>
                </div>
                <div class="w-full mt-2 rounded-md bg-slate-200 dark:bg-slate-600 p-6">
                    <ul class="lg:grid lg:grid-cols-4 lg:space-x-2 text-center p-2 text-gray-600 dark:text-gray-200">
                        <li v-for="(item, index) in pageInfo.categories" :key="`${item.id}_${index}`">{{ t(`site.${item.title}`) }}</li>
                    </ul>
                </div>
            </div>
            <div class="w-full mt-8">
                <h3 class="text-lg font-semibold text-gray-600 dark:text-gray-200">{{ t('common.InquiryTitle') }}</h3>
                <div class="w-full lg:flex lg:justify-between lg:space-x-5 mt-2">
                    <div class="flex lg:w-1/3 bg-gray-200 dark:bg-gray-600 rounded-lg shadow-lg p-6 space-x-8 justify-center mt-1">
                        <div class="flex flex-col justify-center cursor-pointer" @click="fnMoveInquery">
                            <i class="fa-brands fa-rocketchat text-2xl rounded-full bg-gray-600 dark:bg-gray-200 w-16 h-16 flex items-center justify-center m-auto text-gray-100 dark:text-gray-800"></i>
                            <p class="mt-1 text-gray-600 dark:text-gray-200">{{ t('common.Inquiry') }}</p>
                        </div>
                        <div class="border-l border-gray-500 w-1 h-18"></div>
                        <div class="flex flex-col justify-center cursor-pointer" @click="fnMoveInqueryList">
                            <i class="fa-solid fa-scroll text-2xl rounded-full bg-gray-600 dark:bg-gray-200 w-16 h-16 flex items-center justify-center m-auto text-gray-100 dark:text-gray-800"></i>
                            <p class="mt-1 text-gray-600 dark:text-gray-200">{{ t('common.InquiryHistory') }}</p>
                        </div>
                    </div>
                    <div class="lg:w-1/3 bg-gray-200 dark:bg-gray-600 rounded-lg shadow-lg p-6 mt-2">
                        <h3 class="text-lg text-gray-600 dark:text-gray-200">{{ t('common.emailRequest') }}</h3>
                        <a class="text-violet-400" :href="`mailto:${config.info.email}`" target="_blank">{{ config.info.email }}</a>
                        <!--<p class="text-xs mt-3 text-gray-600 dark:text-gray-400">{{ t('site.worktime') }}</p>-->
                        <!--<p class="text-xs mt-1 text-gray-600 dark:text-gray-400">{{ t('site.worktime2') }}</p>-->
                    </div>
                    <div class="lg:w-1/3 bg-gray-200 dark:bg-gray-600 rounded-lg shadow-lg p-6 mt-2">
                        <div style="overflow: hidden;">
                            <div class="flex">
                                <i class="fa-brands fa-github text-gray-600 dark:text-white text-lg"></i>
                                <h3 class="text-lg text-gray-600 dark:text-gray-200 mt-0.5 ml-1">{{ t('common.Bug') }}</h3>
                            </div>
                            <p class="text-xs mt-3 text-gray-600 dark:text-gray-400">{{ t('common.bugment') }}</p>
                            <GButton class="float-right rounded-full w-16 h-16 overflow-hidden bg-white inline-block text-center" @click="fnPopupShow"><i class="fa-solid fa-bug text-slate-600 text-2xl"></i></GButton>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-8">
                <NoticeBox class="" />
            </div>
        </div>
    </div>
</GContainer>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { LinkItem, NotifyRepository, Member, BoardRepository,BoardCategory } from '@gn2studio/gn2.library.common';
    import config from '@/config';
    import { HeadSet, MessageBox } from '@/utility';
    import { useHead } from '@vueuse/head';
    import { useI18n } from 'vue-i18n';
    import { GContainer,GInput,DefaultButton,NoticeBox,GButton,CustomerMenu } from '@/components';
    import { useMemberStore,useGlobalStore } from '@/store';
    import { AuthService } from '@/services';

    interface containerParameter {
        class?:string|null
    };

    const { t, locale } = useI18n();
    const property = defineProps<containerParameter>();
    const route = useRoute();
    const router = useRouter();
    const site = useGlobalStore();
    const auth = new AuthService();
    const memberStore = useMemberStore();

    var notifyRep = new NotifyRepository(config.apis.notification, '', config.global.slug);
    var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

    var pageInfo = ref({
        key:0,
        class:'',
        isLoggedIn:false,
        isLoaded:false,
        item:{} as LinkItem,
        accessToken:'',
        member:new Member(),
        categories:[] as BoardCategory[]
    });

    onMounted(async () => {
        pageInfo.value.class = property.class ?? '';
        pageInfo.value.isLoaded = true;

        memberStore.loadFromLocalStorage();
        let token = await auth.getAccessToken();
        if (token !== null && token !== undefined && String(token).trim() !== '') {
            pageInfo.value.accessToken = token;
            pageInfo.value.member = memberStore.member;
            notifyRep = new NotifyRepository(config.apis.notification, token, config.global.slug);
            pageInfo.value.isLoggedIn = true;
        }

        const head = new HeadSet();
        head.title = `${ t('site.customer') } - MerryTokTok`;
        head.url = `${location.origin}/customer`;
        useHead(head.CreateJson());

        await getCategory();

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const fnPopupShow = () => {
        if (pageInfo.value.isLoggedIn) {
            router.push(`/customer/bug`);
        } else {
            MessageBox.Confirm(t('common.requireLogin'), () => {
                router.push(`/login?backurl=/customer/bug`);
            });
        }
    }

    const getCategory = async () => {
        let rst = await boardRep.GetBoardCategories('faq');
        if (rst !== null && rst !== undefined && rst.check) {
            pageInfo.value.categories = rst.data;
        }
    };

    const fnMoveInquery = () => {
        router.push('/customer/qna/edit');
    }

    const fnMoveInqueryList = () => {
        router.push('/customer/qna');
    }
</script>

<style scoped>
ul.articleList li:last-child { border-bottom:none; }
</style>