<template>
<div class="flex justify-center items-center space-x-3" :class="componentInfo.class" v-if="componentInfo.isLoggedIn" :key="`contentBox_${componentInfo.key}`">
    <button class="flex items-center align-middle text-pink-700 border border-gray-100 py-3 px-3 rounded-xl hover:bg-pink-700 hover:text-white" @click="fnLike">
        <i class="text-2xl fa-solid fa-heart"></i>
        <span class="text-4xl font-bold ml-1">{{ componentInfo.content.likeCount }}</span>
    </button>
    <button class="flex items-center align-middle text-orange-700 border border-gray-100 py-3 px-4 rounded-xl hover:bg-orange-700 hover:text-white" @click="fnShare">
        <i class="text-2xl fa-solid fa-share-nodes"></i>
        <span class="text-4xl font-bold ml-1">{{ componentInfo.content.shareCount }}</span>
    </button>
</div>
<div class="flex justify-center items-center space-x-3" :class="componentInfo.class" v-else :key="`contentBox_nologin_${componentInfo.key}`">
    <button class="flex items-center align-middle text-pink-700 border border-gray-100 py-3 px-3 rounded-xl hover:bg-pink-700 hover:text-white" @click="fnAlert">
        <i class="text-2xl fa-solid fa-heart"></i>
        <span class="text-4xl font-bold ml-1">{{ componentInfo.content.likeCount }}</span>
    </button>
    <button class="flex items-center align-middle text-orange-700 border border-gray-100 py-3 px-4 rounded-xl hover:bg-orange-700 hover:text-white" @click="fnShare">
        <i class="text-2xl fa-solid fa-share-nodes"></i>
        <span class="text-4xl font-bold ml-1">{{ componentInfo.content.shareCount }}</span>
    </button>
</div>
</template>

<script setup lang="ts">
import config from '@/config';
import router from '@/router';
import { Content, ContentRepository, Member } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { useI18n } from 'vue-i18n';

interface contentBoxParameter {
    content:Content,
    class?:string|null,
};

const { t, locale } = useI18n();
const property = defineProps<contentBoxParameter>();
const auth = new AuthService();
const memberStore = useMemberStore();

var repository = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
    key:0,
    content: new Content(),
    member:new Member(),
    accessToken:'',
    class:'',
    isLoggedIn:false
});

onMounted(async () => {
    componentInfo.value.content = property.content;
    componentInfo.value.class = property.class ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        repository = new ContentRepository(config.apis.article, token);
        componentInfo.value.isLoggedIn = true;
    }
});

const fnLike = async () => {
    let rst = await repository.ContentLike(componentInfo.value.content.id);
    if (rst.check) {
        componentInfo.value.content.likeCount += 1;
        componentInfo.value.key += 1;
    } else {
        MessageBox.Alert(rst.message);
    }
};

const fnAlert = () => {
    MessageBox.Alert(t("common.requireLogin"), () => {
        router.push('/login');
    });
}

const fnShare = async () => {
    let rst = await repository.ShareUp(componentInfo.value.content.id);
    if (rst.check) {
        let url = `https://umr.kr/merrytoktok/${componentInfo.value.content.id}`;
        const textarea = document.createElement('textarea');
        textarea.value = url;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        MessageBox.Success(t("common.copyComplete"));

        componentInfo.value.content.shareCount += 1;
        componentInfo.value.key += 1;
    }
};
</script>