import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-4xl font-bold ml-1" }
const _hoisted_2 = { class: "text-4xl font-bold ml-1" }
const _hoisted_3 = { class: "text-4xl font-bold ml-1" }
const _hoisted_4 = { class: "text-4xl font-bold ml-1" }

import config from '@/config';
import router from '@/router';
import { Content, ContentRepository, Member } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { useI18n } from 'vue-i18n';

interface contentBoxParameter {
    content:Content,
    class?:string|null,
}
export default /*@__PURE__*/_defineComponent({
  __name: 'ContentBox',
  props: {
    content: {},
    class: {}
  },
  setup(__props: any) {

;

const { t, locale } = useI18n();
const property = __props;
const auth = new AuthService();
const memberStore = useMemberStore();

var repository = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
    key:0,
    content: new Content(),
    member:new Member(),
    accessToken:'',
    class:'',
    isLoggedIn:false
});

onMounted(async () => {
    componentInfo.value.content = property.content;
    componentInfo.value.class = property.class ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        repository = new ContentRepository(config.apis.article, token);
        componentInfo.value.isLoggedIn = true;
    }
});

const fnLike = async () => {
    let rst = await repository.ContentLike(componentInfo.value.content.id);
    if (rst.check) {
        componentInfo.value.content.likeCount += 1;
        componentInfo.value.key += 1;
    } else {
        MessageBox.Alert(rst.message);
    }
};

const fnAlert = () => {
    MessageBox.Alert(t("common.requireLogin"), () => {
        router.push('/login');
    });
}

const fnShare = async () => {
    let rst = await repository.ShareUp(componentInfo.value.content.id);
    if (rst.check) {
        let url = `https://umr.kr/merrytoktok/${componentInfo.value.content.id}`;
        const textarea = document.createElement('textarea');
        textarea.value = url;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        MessageBox.Success(t("common.copyComplete"));

        componentInfo.value.content.shareCount += 1;
        componentInfo.value.key += 1;
    }
};

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex justify-center items-center space-x-3", _unref(componentInfo).class]),
        key: `contentBox_${_unref(componentInfo).key}`
      }, [
        _createElementVNode("button", {
          class: "flex items-center align-middle text-pink-700 border border-gray-100 py-3 px-3 rounded-xl hover:bg-pink-700 hover:text-white",
          onClick: fnLike
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "text-2xl fa-solid fa-heart" }, null, -1)),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(componentInfo).content.likeCount), 1)
        ]),
        _createElementVNode("button", {
          class: "flex items-center align-middle text-orange-700 border border-gray-100 py-3 px-4 rounded-xl hover:bg-orange-700 hover:text-white",
          onClick: fnShare
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "text-2xl fa-solid fa-share-nodes" }, null, -1)),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(componentInfo).content.shareCount), 1)
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex justify-center items-center space-x-3", _unref(componentInfo).class]),
        key: `contentBox_nologin_${_unref(componentInfo).key}`
      }, [
        _createElementVNode("button", {
          class: "flex items-center align-middle text-pink-700 border border-gray-100 py-3 px-3 rounded-xl hover:bg-pink-700 hover:text-white",
          onClick: fnAlert
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "text-2xl fa-solid fa-heart" }, null, -1)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(componentInfo).content.likeCount), 1)
        ]),
        _createElementVNode("button", {
          class: "flex items-center align-middle text-orange-700 border border-gray-100 py-3 px-4 rounded-xl hover:bg-orange-700 hover:text-white",
          onClick: fnShare
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "text-2xl fa-solid fa-share-nodes" }, null, -1)),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(componentInfo).content.shareCount), 1)
        ])
      ], 2))
}
}

})