<template>
  <footer class="bg-slate-900 mt-12">
        <!-- Flex Container -->
        <div class="container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
        <!-- Logo and social links container -->
          <div class="flex flex-col-reverse items-center justify-between space-y-12 lg:flex-col lg:space-y-0 lg:items-start">
          <!-- Logo -->
            <div>
              <img src="/logo/Logo_Blue.png" alt="logo image" class="w-[146px] h-auto filter invert brightness-0" />
            </div>
            <div class=""></div>
          </div>
        <!-- List Container -->
          <div class="flex justify-around space-x-32">
            <div class="flex flex-col space-y-3 text-white">
                <a href="/content/list/이슈" class="hover:text-brightRed">이슈</a>
                <a href="/content/list/경제" class="hover:text-brightRed">경제</a>
                <a href="/content/list/정치" class="hover:text-brightRed">정치</a>
                <a href="/content/list/직장" class="hover:text-brightRed">직장</a>
                <a href="/content/list/포토" class="hover:text-brightRed">포토</a>
                <a href="/content/list/만화" class="hover:text-brightRed">만화</a>
            </div>
            <div class="flex flex-col space-y-3 text-white">
                <a href="/content/list/얼짱,몸짱" class="hover:text-brightRed">얼짱,몸짱</a>
                <a href="/content/list/연예인" class="hover:text-brightRed">연예인</a>
                <a href="/content/list/여행,장소" class="hover:text-brightRed">여행,장소</a>
                <a href="/content/list/영화,드라마" class="hover:text-brightRed">영화,드라마</a>
                <a href="/content/list/추억" class="hover:text-brightRed">추억</a>
                <a href="/content/list/영상" class="hover:text-brightRed">영상</a>
            </div>
          </div>

          <div class="flex flex-col justify-between">
            <div></div>
            <div class="flex justify-center space-x-4 text-white">
              <a :href="config.link.facebook" target="_blank">
                  <i class="fa-brands fa-facebook"></i>
                </a>
                <a :href="config.link.youtube" target="_blank">
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a :href="config.link.blog" target="_blank">
                  <i class="fa-solid fa-blog"></i>
                </a>
            </div>
          </div>
        </div>
  </footer>
  <footer class="bg-slate-700" :class="componentInfo.class">
    <GContainer>
        <GBetweenSide class="p-2">
            <template #left>
                <div class="text-gray-300 mt-2">©{{ componentInfo.year }} <span>MerryTokTok.com</span>. All rights reserved.</div>
            </template>
            <template #right>
              <div class="flex justify-start lg:justify-end space-x-5 text-white mt-1">
                  <router-link to="/document/privacy">개인정보보호정책</router-link>
                  <router-link to="/document/term">이용약관</router-link>
                </div>
            </template>
        </GBetweenSide>
    </GContainer>
  </footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { GContainer,GBetweenSide } from '../atoms';
import { ValidateHelper } from '@gn2studio/gn2.library.common';
import router from '@/router';
import config from '@/config';

interface containerParameter {
    class?:string|null
};

const property = defineProps<containerParameter>();
const validate = new ValidateHelper();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:''
});

onMounted(() => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';
});

</script>