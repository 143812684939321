<template>
  <GContainer :key="`homepage_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class">
  <div class="w-full flex justify-between mt-5 p-2 lg:p-0">
      <LeftSection class="hidden lg:block" />
      <section class="w-full lg:w-[840px]">
        <HomePage />
      </section>
      <RightSection class="hidden lg:block" />
  </div>
  </GContainer>
</template>

<script setup lang="ts">
import { HomePage } from '@/components';
import { GContainer,LeftSection,RightSection } from '@/components';
import { onMounted, ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore } from '@/store';

const auth = new AuthService();
const site = useGlobalStore();

var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false
});

onMounted(async () => {
  componentInfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 100);
});
</script>
