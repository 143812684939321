<template>
<GContainer :key="`content_list_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class">
    <div class="w-full flex justify-between mt-5 p-2 lg:p-0">
        <LeftSection class="hidden lg:block" />
        <section class="w-full lg:w-[840px]">
            <WideBanner adid="2064279846" :key="`content_banner_${componentInfo.key}`"></WideBanner>
            <div class="w-full mt-4">
                <TitleBox :title="componentInfo.tag"></TitleBox>
            </div>
            <ContentListPage :class="componentInfo.class" :selectedContent="goContent" />
        </section>
        <RightSection class="hidden lg:block" />
    </div>
</GContainer>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { GContainer,LeftSection,RightSection,ContentListPage,WideBanner,TitleBox } from '@/components';
    import SiteData from '../../../data';
    import { Content, LinkItem } from '@gn2studio/gn2.library.common';
    import { useGlobalStore } from '@/store';
    import { HeadSet } from '@/utility';
    import { useHead } from '@vueuse/head';
    import { useI18n } from 'vue-i18n';

    interface containerParameter {
        class?:string|null
    };

    const { t, locale } = useI18n();
    const property = defineProps<containerParameter>();
    const route = useRoute();
    const router = useRouter();
    const site = useGlobalStore();
    const curpage = computed(() => route.query.curpage ?? 1);

    var componentInfo = ref({
        key:0,
        class:'',
        isLoaded:false,
        tag:'' as string,
        item:{} as LinkItem
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
        componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;
        componentInfo.value.isLoaded = true;

        const head = new HeadSet();
        head.title = `${t('site.topics')} - MerryTokTok`;
        head.url = `${location.origin}/content/list`;
        useHead(head.CreateJson());

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const goContent = (content:Content) => {
        document.location.href = `/content/view/${content.id}?curpage=${curpage.value}`;
    }
</script>

<style scoped>
ul.articleList li:last-child { border-bottom:none; }
</style>