<template>
<div class="flex justify-between my-3 py-3 border-t border-gray-300 dark:border-gray-600" :class="componentInfo.class" v-if="componentInfo.isLoggedIn">
    <div class="flex justify-start space-x-1">
        <DefaultButton class="" :click-event="fnRegist" icon="fa-solid fa-pen-to-square" size="md" type="default">{{ t('common.ButtonAdd') }}</DefaultButton>
        <DefaultButton class="" :click-event="fnEdit" icon="fa-solid fa-screwdriver-wrench" size="md" type="default"
         v-if="componentInfo.content.id !== null && componentInfo.content.id !== undefined && String(componentInfo.content.id).trim() !== ''">{{ t('common.Edit') }}</DefaultButton>
    </div>
    <div class="">
        <DefaultButton class="" :click-event="onDelete" icon="fa-solid fa-eraser" size="md" type="default"
         v-if="componentInfo.content.id !== null && componentInfo.content.id !== undefined && String(componentInfo.content.id).trim() !== ''">{{ t('common.Delete') }}</DefaultButton>
    </div>
</div>

</template>

<script setup lang="ts">
import { Content, ContentRepository, Member } from '@gn2studio/gn2.library.common';
import { computed, onMounted, ref } from 'vue';
import DefaultButton from '../molecules/DefaultButton.vue';
import { MessageBox } from '@/utility';
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import { useI18n } from 'vue-i18n';
import { useRouter,useRoute } from 'vue-router';

interface managerBoxParameter {
    class?:string|null|undefined,
    content?:Content|null|undefined
};

const { t, locale } = useI18n();
const property = defineProps<managerBoxParameter>();
const auth = new AuthService();
const memberStore = useMemberStore();
const router = useRouter();
const route = useRoute();
const curpage = computed(() => String(route.query.curpage) ?? '1');

var contentRep = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
   class:'',
   accessToken:'',
   content:new Content(),
   member:new Member(),
   isLoggedIn:false,
   tag:''
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.content = property.content ?? new Content();
    componentInfo.value.tag = componentInfo.value.content.tags;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new ContentRepository(config.apis.article, token);
        componentInfo.value.isLoggedIn = true;
    }    
});

const fnRegist = () => {
    router.push(`/content/edit`);
};

const fnEdit = () => {
    router.push(`/content/edit/${componentInfo.value.content.id}`);
};

const onDelete = () => {
    if (componentInfo.value.content.id !== null && componentInfo.value.content.id !== undefined && String(componentInfo.value.content.id).trim() !== '') {
        MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
        let rst = await contentRep.ContentRemove(componentInfo.value.content.id);
            if (rst.check) {
                MessageBox.Success(t('system.Deleted'), () => {
                    location.href = `/content/list/${componentInfo.value.tag}?curpage=${curpage.value}`;
                });
            } else {
                MessageBox.Alert(rst.message);
            }
        });
    }
}
</script>