import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-slate-900 mt-12" }
const _hoisted_2 = { class: "container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0" }
const _hoisted_3 = { class: "flex flex-col justify-between" }
const _hoisted_4 = { class: "flex justify-center space-x-4 text-white" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "text-gray-300 mt-2" }
const _hoisted_9 = { class: "flex justify-start lg:justify-end space-x-5 text-white mt-1" }

import { onMounted, ref } from 'vue';
import { GContainer,GBetweenSide } from '../atoms';
import { ValidateHelper } from '@gn2studio/gn2.library.common';
import router from '@/router';
import config from '@/config';

interface containerParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const property = __props;
const validate = new ValidateHelper();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:''
});

onMounted(() => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"flex flex-col-reverse items-center justify-between space-y-12 lg:flex-col lg:space-y-0 lg:items-start\"><div><img src=\"/logo/Logo_Blue.png\" alt=\"logo image\" class=\"w-[146px] h-auto filter invert brightness-0\"></div><div class=\"\"></div></div><div class=\"flex justify-around space-x-32\"><div class=\"flex flex-col space-y-3 text-white\"><a href=\"/content/list/이슈\" class=\"hover:text-brightRed\">이슈</a><a href=\"/content/list/경제\" class=\"hover:text-brightRed\">경제</a><a href=\"/content/list/정치\" class=\"hover:text-brightRed\">정치</a><a href=\"/content/list/직장\" class=\"hover:text-brightRed\">직장</a><a href=\"/content/list/포토\" class=\"hover:text-brightRed\">포토</a><a href=\"/content/list/만화\" class=\"hover:text-brightRed\">만화</a></div><div class=\"flex flex-col space-y-3 text-white\"><a href=\"/content/list/얼짱,몸짱\" class=\"hover:text-brightRed\">얼짱,몸짱</a><a href=\"/content/list/연예인\" class=\"hover:text-brightRed\">연예인</a><a href=\"/content/list/여행,장소\" class=\"hover:text-brightRed\">여행,장소</a><a href=\"/content/list/영화,드라마\" class=\"hover:text-brightRed\">영화,드라마</a><a href=\"/content/list/추억\" class=\"hover:text-brightRed\">추억</a><a href=\"/content/list/영상\" class=\"hover:text-brightRed\">영상</a></div></div>", 2)),
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              href: _unref(config).link.facebook,
              target: "_blank"
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "fa-brands fa-facebook" }, null, -1)
            ]), 8, _hoisted_5),
            _createElementVNode("a", {
              href: _unref(config).link.youtube,
              target: "_blank"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "fa-brands fa-youtube" }, null, -1)
            ]), 8, _hoisted_6),
            _createElementVNode("a", {
              href: _unref(config).link.blog,
              target: "_blank"
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "fa-solid fa-blog" }, null, -1)
            ]), 8, _hoisted_7)
          ])
        ])
      ])
    ]),
    _createElementVNode("footer", {
      class: _normalizeClass(["bg-slate-700", _unref(componentInfo).class])
    }, [
      _createVNode(_unref(GContainer), null, {
        default: _withCtx(() => [
          _createVNode(_unref(GBetweenSide), { class: "p-2" }, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createTextVNode("©" + _toDisplayString(_unref(componentInfo).year) + " ", 1),
                _cache[5] || (_cache[5] = _createElementVNode("span", null, "MerryTokTok.com", -1)),
                _cache[6] || (_cache[6] = _createTextVNode(". All rights reserved."))
              ])
            ]),
            right: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_router_link, { to: "/document/privacy" }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("개인정보보호정책")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/document/term" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("이용약관")
                  ])),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2)
  ], 64))
}
}

})