import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-4 p-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "w-full flex justify-start space-x-2 px-2" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_6 = { class: "w-full flex justify-start space-x-2 px-2" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-2" }

import { onMounted, ref } from 'vue';
    import { TitleBox } from '../molecules';
    import { ArticleItem } from '../organisms';
    import { Content, ContentRepository } from '@gn2studio/gn2.library.common';
    import config from '@/config';
    import router from '@/router';
    import { Skeleton } from '../atoms';

    interface articleParameter {
        tag:string,
        class?:string|null|undefined,
        icon?:string|null|undefined,
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'Articles',
  props: {
    tag: {},
    class: {},
    icon: {}
  },
  setup(__props: any) {

    ;

    const property = __props;
    const repository = new ContentRepository(config.apis.article, '');

    var componentInfo = ref({
        key:0,
        isLoaded:false,
        class:'',
        tag:'',
        icon:'',
        items:[] as Content[]
    });

    onMounted(async () => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.tag = property.tag ?? '';
        componentInfo.value.icon = property.icon ?? '';

        await getTagContent(componentInfo.value.tag);

        setTimeout(() => {
            componentInfo.value.isLoaded = true;
        }, 200)
        
    });

    const getTagContent = async (tag:string) => {
        let rst = await repository.GetTagContents(tag, { curPage : 1, pageSize : 5, keyword : "" });
        if (rst.check) {
            componentInfo.value.items = rst.data;
        }
    };

    const touchContent = (content:Content) => {
        router.push(`/content/view/${content.id}`);
    }

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("article", {
        key: `article_${_unref(componentInfo).key}`,
        class: _normalizeClass(_unref(componentInfo).class)
      }, [
        _createVNode(_unref(TitleBox), {
          title: _unref(componentInfo).tag,
          icon: _unref(componentInfo).icon
        }, null, 8, ["title", "icon"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `article_${index}`,
                class: "last-hide border-b border-gray-600 pb-5 mb-5 flex justify-start space-x-2",
                onClick: ($event: any) => (touchContent(item))
              }, [
                _createVNode(_unref(ArticleItem), { content: item }, null, 8, ["content"])
              ], 8, _hoisted_3))
            }), 128))
          ])
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("article", {
        key: 1,
        class: _normalizeClass([_unref(componentInfo).class, "w-full flex flex-col space-y-2"])
      }, [
        _createVNode(_unref(Skeleton), { class: "w-full h-[84px]" }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(Skeleton), { class: "w-[74px] h-[90px]" }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(Skeleton), { class: "w-[80%] h-[20px]" }),
            _createVNode(_unref(Skeleton), { class: "w-[60%] h-[20px]" }),
            _createVNode(_unref(Skeleton), { class: "w-[60%] h-[20px]" })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(Skeleton), { class: "w-[74px] h-[90px]" }),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(Skeleton), { class: "w-[80%] h-[20px]" }),
            _createVNode(_unref(Skeleton), { class: "w-[60%] h-[20px]" }),
            _createVNode(_unref(Skeleton), { class: "w-[60%] h-[20px]" })
          ])
        ])
      ], 2))
}
}

})