<template>
<main :key="`content_list_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="w-full px-2">
    <div class="w-full mt-4">
        <TitleBox :title="componentInfo.item.title"></TitleBox>
    </div>
    <div class="w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4">
        <div class="w-full py-4 dark:text-gray-200">
        <div class="lg:flex lg:space-x-2">
            <h3 class="text-2xl">{{ componentInfo.content.title }}</h3>
        </div>
        <hr class="my-3 border-t dark:border-gray-800" />
        <div class="flex w-full justify-between dark:text-gray-400">
            <div class="hidden lg:block text-blue-600 dark:text-blue-300">{{ componentInfo.content.writer }}</div>
            <div class="hidden lg:block text-gray-500">{{ t('common.Read') }}: {{ componentInfo.content.readCount }}</div>
            <div class="hidden lg:block text-gray-500">{{ t('common.RegistDateTime') }}:{{ dayjs.utc(componentInfo.content.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</div>
            <div class="block lg:hidden">{{ componentInfo.content.writer }}</div>
            <div class="block lg:hidden">{{ componentInfo.content.readCount }}</div>
            <div class="block lg:hidden">{{ dayjs.utc(componentInfo.content.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</div>
        </div>
        <hr class="my-3 border-t dark:border-gray-800" />
        <div class="p-0 lg:p-3 text-gray-600 dark:text-gray-200" v-html="sanitizeHtml(componentInfo.content.body)"></div>
        </div>
    </div>
    <div class="w-full my-4">
        <ContentBox class="" :content="componentInfo.content" />
    </div>
    <div class="w-full my-4" v-if="componentInfo.isLoggedIn">
        <ManagerBox class="" :content="componentInfo.content" />
    </div>
    <div class="w-full border-t border-gray-100 dark:border-gray-500 mt-3">
        <Reply class="" :id="componentInfo.content.id" :comments="componentInfo.content.replies" />
    </div>
    <div class="w-full border-t border-gray-100 dark:border-gray-500 mt-3">
        <ContentListPage class="" :selected-content="setContent" :tag="componentInfo.content.tags" />
    </div>
</main>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { TitleBox,Reply,ContentBox,ManagerBox } from '@/components';
import SiteData from '../../../data';
import { Content, LinkItem, ContentRepository, Member } from '@gn2studio/gn2.library.common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import ContentListPage from './ContentListPage.vue';
import { useReadCheckStore,useMemberStore } from '@/store';
import { AuthService } from '@/services';
import { useI18n } from 'vue-i18n';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface containerParameter {
    class?:string|null,
    contentChangeEvent?:Function|null|undefined
};

const { t, locale } = useI18n();
const property = defineProps<containerParameter>();
const route = useRoute();
const router = useRouter();
const contentId = computed(() => String(route.params.id));
const auth = new AuthService();
const readCheck = useReadCheckStore();
const memberStore = useMemberStore();

var contentRep = new ContentRepository(config.apis.article, '');
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    tag:'' as string,
    content: new Content(),
    item:{} as LinkItem,
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new ContentRepository(config.apis.article, token);
        componentInfo.value.isLoggedIn = true;
    }

    let rst = await contentRep.GetContent(contentId.value);
    if (rst.check && rst.data !== null && rst.data !== undefined) {
        componentInfo.value.content = rst.data;
        componentInfo.value.tag = componentInfo.value.content.tags;
        componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;
    }

    componentInfo.value.isLoaded = true;
});


const setContent = async (content:Content) => {
    let curpage = (route.query.curpage !== null && route.query.curpage !== undefined) ? Number(route.query.curpage) : 1;
    router.push(`/content/view/${content.id}?curpage=${curpage}`);
    componentInfo.value.content = content;

    if (!readCheck.hasValue('content', content.id))
    {
        readCheck.addValue('content', content.id);
        await contentRep.ContentRead(content.id);
    }

    if (property.contentChangeEvent !== null && property.contentChangeEvent !== undefined && typeof property.contentChangeEvent === "function") {
        property.contentChangeEvent(content);
    }

    componentInfo.value.key += 1;
}

const sanitizeHtml = (htmlString:string):string => {
    if (!htmlString || typeof htmlString !== "string") {
        throw new Error("Invalid input: HTML string is required.");
    }

    htmlString = htmlString.replace(/<script[\s\S]*?<\/script>/gi, "");

    htmlString = htmlString.replace(/style\s*=\s*(['"])[\s\S]*?\1/gi, "");

    return updateIframeWidth(htmlString);
}


const updateIframeWidth = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // 모든 iframe 태그를 선택
    const iframes = doc.querySelectorAll('iframe');

    iframes.forEach(iframe => {
        const originalWidth = parseFloat(iframe.getAttribute('width') || '0');
        const originalHeight = parseFloat(iframe.getAttribute('height') || '0');

        if (originalWidth && originalHeight) {
            // 비율 계산
            const aspectRatio = (originalHeight / originalWidth) * 100;

            // 부모 div 생성
            const wrapper = doc.createElement('div');
            wrapper.style.position = 'relative';
            wrapper.style.width = '100%';
            wrapper.style.paddingTop = `${aspectRatio}%`;
            wrapper.style.overflow = 'hidden';

            // iframe 스타일 변경
            iframe.style.position = 'absolute';
            iframe.style.top = '0';
            iframe.style.left = '0';
            iframe.width = `100%`;
            iframe.height = '100%';
            iframe.style.border = 'none';

            // iframe을 부모 div로 감싸기
            wrapper.appendChild(iframe.cloneNode(true));
            iframe.replaceWith(wrapper);
        }
    });

    return doc.body.innerHTML;
}
</script>
