import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between mt-5 p-2 lg:p-0" }
const _hoisted_2 = {
  class: "w-full lg:w-[840px]",
  id: "contentTop"
}

import { computed, onMounted, ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GContainer,LeftSection,RightSection,CustomerViewPage,WideBanner } from '@/components';
import SiteData from '../../../data';
import { Content, ContentRepository, LinkItem, StringHelper } from '@gn2studio/gn2.library.common';
import { HeadSet } from '@/utility';
import { useHead } from '@vueuse/head';
import config from '@/config';
import { useGlobalStore } from '@/store';

interface containerParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'NoticeView',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const property = __props;
const route = useRoute();
const router = useRouter();
const contentId = computed(() => String(route.params.id));
const contentRep = new ContentRepository(config.apis.article, '');
const site = useGlobalStore();
const head = new HeadSet();

var pageinfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    tag:'' as string,
    item:{} as LinkItem
});

onMounted(async () => {
    let rst = await contentRep.GetContent(contentId.value);
    if (rst.check && rst.data !== null && rst.data !== undefined) {
        setHead(rst.data);
    }

    pageinfo.value.class = property.class ?? '';
    pageinfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    pageinfo.value.item = SiteData.tags.find((x => x.title === pageinfo.value.tag)) ?? {} as LinkItem;
    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

const setHead = (content:Content) => {
    head.title = `${content.title} - MerryTokTok`;
    head.description = content.title;
    head.url = `${location.origin}/customer/notice/${content.id}`;
    useHead(head.CreateJson());
    scrollToSection('contentTop');
}

const scrollToSection = (id:string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `content_list_${_unref(pageinfo).key}`,
        class: _normalizeClass(_unref(pageinfo).class)
      }, [
        _createVNode(_unref(GContainer), null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(LeftSection), { class: "hidden lg:block" }),
              _createElementVNode("section", _hoisted_2, [
                (_openBlock(), _createBlock(_unref(WideBanner), {
                  adid: "2064279846",
                  key: `content_banner_${_unref(pageinfo).key}`
                })),
                _createVNode(_unref(CustomerViewPage), {
                  class: "",
                  contentChangeEvent: setHead
                })
              ]),
              _createVNode(_unref(RightSection), { class: "hidden lg:block" })
            ])
          ]),
          _: 1
        })
      ], 2))
    : _createCommentVNode("", true)
}
}

})