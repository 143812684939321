<template>
    <div class="w-full flex justify-start space-x-2 cursor-pointer" :class="componentInfo.class" :key="componentInfo.key" v-if="componentInfo.isLoaded">
        <BookCover :src="StringHelper.extractYoutubeThumbnailsFromHtml(componentInfo.content.body)" width="75px" height="90px" :border="2"
        v-if="StringHelper.extractYoutubeThumbnailsFromHtml(componentInfo.content.body) !== null && StringHelper.extractYoutubeThumbnailsFromHtml(componentInfo.content.body) !== undefined && StringHelper.extractYoutubeThumbnailsFromHtml(componentInfo.content.body).trim() !== ''" />
        <div class="imgItem">
            <div class="w-full flex justify-start space-x-2 text-sm text-gray-600 dark:text-gray-300">
                <CircleImage :src="componentInfo.content.member.photo" width="20px" height="20px" :border="1" class="mt-0.5" @click="goProfile(componentInfo.content.ownerId)" />
                <span class="mt-0.5" @click="goProfile(componentInfo.content.ownerId)">{{ componentInfo.content.member.nickName }}</span>
                <span class="mt-0.5">{{ dayjs.utc(componentInfo.content.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</span>
            </div>
            <div class="w-full mt-1 text-gray-600 dark:text-gray-300">
                <h3 class="text-md font-bold ml-2">{{ componentInfo.content.title }}</h3>
                <p class="text-sm ml-2">{{ StringHelper.truncateText(StringHelper.extractTextFromHtml(componentInfo.content.body),30).trim() }}</p>
            </div>
        </div>
    </div>
    <div class="w-full flex justify-start space-x-2" v-else>
        <Skeleton class="w-[75px] h-[90px]" />
        <div class="w-[80%] pt-2">
            <Skeleton class="w-[90%] h-[24px]" />
            <Skeleton class="w-[80%] h-[24px] mt-2" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { CircleImage,BookCover } from '../molecules';
    import { Content,StringHelper } from '@gn2studio/gn2.library.common';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import localizedFormat from 'dayjs/plugin/localizedFormat'; 
    import { useRouter } from 'vue-router';
    import { Skeleton } from '../atoms';

    dayjs.extend(utc);
    dayjs.extend(localizedFormat);

    interface articleParameter {
        class?:string|null,
        content:Content
    };

    const property = defineProps<articleParameter>();
    const router = useRouter();

    var componentInfo = ref({
        key:0,
        isLoaded:false,
        class:'',
        content:new Content(),
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.content = property.content ?? new Content();
        setTimeout(() => {
            componentInfo.value.isLoaded = true;
        }, 100);
    });

    const goProfile = (id:string) => {
        router.push(`/profile/${id}`);
    };
</script>

<style scoped>
    .imgItem { width:calc(100% - 75px); }
</style>