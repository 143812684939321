import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between mt-5 p-2 lg:p-0" }
const _hoisted_2 = { class: "w-full lg:w-[840px]" }
const _hoisted_3 = { class: "w-full mt-4" }

import { computed, onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { GContainer,LeftSection,RightSection,ContentListPage,WideBanner,TitleBox } from '@/components';
    import SiteData from '../../../data';
    import { Content, LinkItem } from '@gn2studio/gn2.library.common';
    import { useGlobalStore } from '@/store';
    import { HeadSet } from '@/utility';
    import { useHead } from '@vueuse/head';
    import { useI18n } from 'vue-i18n';

    interface containerParameter {
        class?:string|null
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'List',
  props: {
    class: {}
  },
  setup(__props: any) {

    ;

    const { t, locale } = useI18n();
    const property = __props;
    const route = useRoute();
    const router = useRouter();
    const site = useGlobalStore();
    const curpage = computed(() => route.query.curpage ?? 1);

    var componentInfo = ref({
        key:0,
        class:'',
        isLoaded:false,
        tag:'' as string,
        item:{} as LinkItem
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
        componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;
        componentInfo.value.isLoaded = true;

        const head = new HeadSet();
        head.title = `${t('site.topics')} - MerryTokTok`;
        head.url = `${location.origin}/content/list`;
        useHead(head.CreateJson());

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const goContent = (content:Content) => {
        document.location.href = `/content/view/${content.id}?curpage=${curpage.value}`;
    }

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(GContainer), {
        key: `content_list_${_unref(componentInfo).key}`,
        class: _normalizeClass(_unref(componentInfo).class)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(LeftSection), { class: "hidden lg:block" }),
            _createElementVNode("section", _hoisted_2, [
              (_openBlock(), _createBlock(_unref(WideBanner), {
                adid: "2064279846",
                key: `content_banner_${_unref(componentInfo).key}`
              })),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(TitleBox), {
                  title: _unref(componentInfo).tag
                }, null, 8, ["title"])
              ]),
              _createVNode(_unref(ContentListPage), {
                class: _normalizeClass(_unref(componentInfo).class),
                selectedContent: goContent
              }, null, 8, ["class"])
            ]),
            _createVNode(_unref(RightSection), { class: "hidden lg:block" })
          ])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}
}

})