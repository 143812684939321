import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "imgItem" }
const _hoisted_2 = { class: "w-full flex justify-start space-x-2 text-sm text-gray-600 dark:text-gray-300" }
const _hoisted_3 = { class: "mt-0.5" }
const _hoisted_4 = { class: "w-full mt-1 text-gray-600 dark:text-gray-300" }
const _hoisted_5 = { class: "text-md font-bold ml-2" }
const _hoisted_6 = { class: "text-sm ml-2" }
const _hoisted_7 = {
  key: 1,
  class: "w-full flex justify-start space-x-2"
}
const _hoisted_8 = { class: "w-[80%] pt-2" }

import { onMounted, ref } from 'vue';
    import { CircleImage,BookCover } from '../molecules';
    import { Content,StringHelper } from '@gn2studio/gn2.library.common';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import localizedFormat from 'dayjs/plugin/localizedFormat'; 
    import { useRouter } from 'vue-router';
    import { Skeleton } from '../atoms';

    interface articleParameter {
        class?:string|null,
        content:Content
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'ArticleItem',
  props: {
    class: {},
    content: {}
  },
  setup(__props: any) {

    dayjs.extend(utc);
    dayjs.extend(localizedFormat);

    ;

    const property = __props;
    const router = useRouter();

    var componentInfo = ref({
        key:0,
        isLoaded:false,
        class:'',
        content:new Content(),
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.content = property.content ?? new Content();
        setTimeout(() => {
            componentInfo.value.isLoaded = true;
        }, 100);
    });

    const goProfile = (id:string) => {
        router.push(`/profile/${id}`);
    };

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full flex justify-start space-x-2 cursor-pointer", _unref(componentInfo).class]),
        key: _unref(componentInfo).key
      }, [
        (_unref(StringHelper).extractYoutubeThumbnailsFromHtml(_unref(componentInfo).content.body) !== null && _unref(StringHelper).extractYoutubeThumbnailsFromHtml(_unref(componentInfo).content.body) !== undefined && _unref(StringHelper).extractYoutubeThumbnailsFromHtml(_unref(componentInfo).content.body).trim() !== '')
          ? (_openBlock(), _createBlock(_unref(BookCover), {
              key: 0,
              src: _unref(StringHelper).extractYoutubeThumbnailsFromHtml(_unref(componentInfo).content.body),
              width: "75px",
              height: "90px",
              border: 2
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(CircleImage), {
              src: _unref(componentInfo).content.member.photo,
              width: "20px",
              height: "20px",
              border: 1,
              class: "mt-0.5",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goProfile(_unref(componentInfo).content.ownerId)))
            }, null, 8, ["src"]),
            _createElementVNode("span", {
              class: "mt-0.5",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (goProfile(_unref(componentInfo).content.ownerId)))
            }, _toDisplayString(_unref(componentInfo).content.member.nickName), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(dayjs).utc(_unref(componentInfo).content.condition.registDate).local().format('YYYY-MM-DD HH:mm')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_unref(componentInfo).content.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(StringHelper).truncateText(_unref(StringHelper).extractTextFromHtml(_unref(componentInfo).content.body),30).trim()), 1)
          ])
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createVNode(_unref(Skeleton), { class: "w-[75px] h-[90px]" }),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(Skeleton), { class: "w-[90%] h-[24px]" }),
          _createVNode(_unref(Skeleton), { class: "w-[80%] h-[24px] mt-2" })
        ])
      ]))
}
}

})