import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto h-screen flex items-center justify-center p-2" }
const _hoisted_2 = { class: "loginbox w-full max-w-4xl overflow-hidden flex justify-between" }
const _hoisted_3 = { class: "left-box box-content p-8 bg-blue-100 dark:bg-gray-800 rounded-lg shadow-sm w-2/5" }
const _hoisted_4 = { class: "w-full block dark:hidden" }
const _hoisted_5 = { class: "w-full hidden dark:block" }
const _hoisted_6 = { class: "text-slate-200 mt-3" }
const _hoisted_7 = { class: "text-2xl font-bold font-sans" }
const _hoisted_8 = { class: "mt-1 text-gray-600 dark:text-gray-400" }
const _hoisted_9 = { class: "flex items-start" }
const _hoisted_10 = { class: "ml-2 text-gray-700 dark:text-gray-300 cursor-pointer text-sm" }

import { GInput, GButton } from '@/components/atoms';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { HeadSet } from '@/utility';
import { useGlobalStore } from '@/store';
import router from '@/router';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'Regist',
  setup(__props) {

const { t, locale } = useI18n();
const auth = new AuthService();
const route = useRoute();
const head = new HeadSet();
const site = useGlobalStore();
head.title = 'Regist - MerryTokTok';

useHead(head.CreateJson());

var pageinfo = ref({
  key:0,
  isLoaded:false,
  data: {
    email:'',
    password:'',
    confirmpassword:'',
    ischeck:false
  },
  callbackUrl: '/'
});

onMounted(() => {
  if (route.query.backurl !== null && route.query.backurl !== undefined) {
    pageinfo.value.callbackUrl = String(route.query.backurl).trim() ?? '';
  }

  setTimeout(() => {
      site.setValue('loading', false);
  }, 100);
});

const fnNext1 = () => {
  document.getElementById('input_password')?.focus();
};

const fnNext2 = () => {
  document.getElementById('confirm_password')?.focus();
};

const fnSubmit = async () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /\S{6,}/;

  if (pageinfo.value.data.email === "") {
    MessageBox.Alert(t('common.requiredEmail'));
  } else if (emailRegex.test(pageinfo.value.data.email) === false) {
    MessageBox.Alert(t('common.regexEmail'));
  } else if (pageinfo.value.data.password === "") {
    MessageBox.Alert(t('common.requiredPassword'));
  } else if (passwordRegex.test(pageinfo.value.data.password) === false) {
    MessageBox.Alert(t('common.regexPassword'));
  } else if (pageinfo.value.data.confirmpassword === "") {
    MessageBox.Alert(t('common.ConfirmPassword'));
  } else if (pageinfo.value.data.ischeck === false) {
    MessageBox.Alert(t('common.agreement'));
  } else {
    site.setValue('loading', true);
    let rst = await auth.apiRegist(pageinfo.value.data.email, pageinfo.value.data.password, pageinfo.value.data.confirmpassword);
    if (rst.check) {
      MessageBox.Success(t('common.registcomplete'), () => {
        router.push('/login');
      });
    } else {
      MessageBox.Alert(rst.message);
    }

    site.setValue('loading', false);
  }
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("img", {
                src: "/logo/logo.png",
                width: "170",
                height: "32",
                alt: "MerryTokTok"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: "/logo/logo_white.png",
                width: "170",
                height: "32",
                alt: "MerryTokTok"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h3", _hoisted_7, _toDisplayString(_unref(t)('site.welcomeMerrytoktok')), 1),
          _createElementVNode("p", _hoisted_8, [
            _createTextVNode(_toDisplayString(_unref(t)('site.welcomeMerrytoktok2')) + " " + _toDisplayString(_unref(t)('common.alreadyAccount')) + " ", 1),
            _createVNode(_component_router_link, {
              to: "/login",
              class: "text-blue-500 font-bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('system.Login')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("form", {
          class: "mt-6 space-y-4",
          onSubmit: fnSubmit
        }, [
          _createElementVNode("div", null, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { class: "block text-gray-700 dark:text-gray-300 font-semibold mb-2" }, "Email", -1)),
            _createVNode(_unref(GInput), {
              type: "email",
              placeholder: _unref(t)('common.requiredEmail'),
              modelValue: _unref(pageinfo).data.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pageinfo).data.email) = $event)),
              class: "w-full p-2 border rounded",
              onKeyup: _withKeys(fnNext1, ["enter"])
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _createElementVNode("div", null, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { class: "block text-gray-700 dark:text-gray-300 font-semibold mb-2" }, "Password", -1)),
            _createVNode(_unref(GInput), {
              type: "password",
              id: "input_password",
              placeholder: _unref(t)('system.requiredPassword'),
              modelValue: _unref(pageinfo).data.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(pageinfo).data.password) = $event)),
              class: "w-full p-2 border rounded",
              onKeyup: _withKeys(fnNext2, ["enter"])
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _createElementVNode("div", null, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "block text-gray-700 dark:text-gray-300 font-semibold mb-2" }, "Confirm Password", -1)),
            _createVNode(_unref(GInput), {
              type: "password",
              id: "confirm_password",
              placeholder: _unref(t)('system.requiredPassword'),
              modelValue: _unref(pageinfo).data.confirmpassword,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(pageinfo).data.confirmpassword) = $event)),
              class: "w-full p-2 border rounded",
              onKeyup: _withKeys(fnSubmit, ["enter"])
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(pageinfo).data.ischeck) = $event)),
              type: "checkbox",
              class: "text-blue-500 focus:ring-2 focus:ring-blue-500 outline-none cursor-pointer mt-1"
            }, null, 512), [
              [_vModelCheckbox, _unref(pageinfo).data.ischeck]
            ]),
            _createElementVNode("label", _hoisted_10, [
              _createTextVNode(_toDisplayString(_unref(t)('common.agree')) + " ", 1),
              _createVNode(_component_router_link, {
                class: "text-blue-400",
                to: "/document/term"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('system.Terms')), 1)
                ]),
                _: 1
              }),
              _cache[9] || (_cache[9] = _createTextVNode(", ")),
              _createVNode(_component_router_link, {
                class: "text-blue-400",
                to: "/document/privacy"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('system.Privacy')), 1)
                ]),
                _: 1
              }),
              _cache[10] || (_cache[10] = _createTextVNode(". "))
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_unref(GButton), {
              size: "md",
              onClick: fnSubmit,
              class: "w-full py-2 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('common.signup')), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "right-box w-3/5 ml-2 hidden md:block" }, [
        _createElementVNode("img", {
          src: "/banners/editor.jpg",
          alt: "Banner",
          class: "w-full h-auto rounded-md shadow-sm"
        })
      ], -1))
    ])
  ]))
}
}

})