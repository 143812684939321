import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3" }
const _hoisted_2 = { class: "hidden lg:block w-1/6" }
const _hoisted_3 = { class: "w-full lg:w-5/6" }
const _hoisted_4 = { class: "w-full mt-4" }

import { onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { GContainer,CustomerMenu,CustomerListPage,WideBanner,TitleBox } from '@/components';
    import { BoardContent } from '@gn2studio/gn2.library.common';
    import { useGlobalStore } from '@/store';
    import { useI18n } from 'vue-i18n';
    import { HeadSet } from '@/utility';
    import { useHead } from '@vueuse/head';

    interface containerParameter {
        class?:string|null
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'NoticeList',
  props: {
    class: {}
  },
  setup(__props: any) {

    ;

    const { t, locale } = useI18n();
    const property = __props;
    const route = useRoute();
    const router = useRouter();
    const site = useGlobalStore();

    var componentInfo = ref({
        key:0,
        class:'',
        isLoaded:false
    });

    onMounted(async () => {
        componentInfo.value.class = property.class ?? '';

        const head = new HeadSet();
        head.title = `${t('common.notice')} - MerryTokTok`;
        head.url = `${location.origin}/customer/notice`;
        useHead(head.CreateJson());

        componentInfo.value.isLoaded = true;

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const goContent = (content:BoardContent) => {
        document.location.href = `/customer/notice/${content.id}`;
    }

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(GContainer), {
        key: `content_layer_${_unref(componentInfo).key}`,
        class: _normalizeClass(_unref(componentInfo).class)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(CustomerMenu), { position: 1 })
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_unref(WideBanner), {
                adid: "2064279846",
                key: `content_banner_${_unref(componentInfo).key}`
              })),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(TitleBox), {
                  title: _unref(t)(`common.notice`),
                  icon: "fa-solid fa-bell"
                }, null, 8, ["title"])
              ]),
              (_openBlock(), _createBlock(_unref(CustomerListPage), {
                class: _normalizeClass(_unref(componentInfo).class),
                selectedContent: goContent,
                key: `content_list_${_unref(componentInfo).key}`
              }, null, 8, ["class"]))
            ])
          ])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}
}

})