import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start space-x-1" }
const _hoisted_2 = { class: "" }

import { Content, ContentRepository, Member } from '@gn2studio/gn2.library.common';
import { computed, onMounted, ref } from 'vue';
import DefaultButton from '../molecules/DefaultButton.vue';
import { MessageBox } from '@/utility';
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import { useI18n } from 'vue-i18n';
import { useRouter,useRoute } from 'vue-router';

interface managerBoxParameter {
    class?:string|null|undefined,
    content?:Content|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'ManagerBox',
  props: {
    class: {},
    content: {}
  },
  setup(__props: any) {

;

const { t, locale } = useI18n();
const property = __props;
const auth = new AuthService();
const memberStore = useMemberStore();
const router = useRouter();
const route = useRoute();
const curpage = computed(() => String(route.query.curpage) ?? '1');

var contentRep = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
   class:'',
   accessToken:'',
   content:new Content(),
   member:new Member(),
   isLoggedIn:false,
   tag:''
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.content = property.content ?? new Content();
    componentInfo.value.tag = componentInfo.value.content.tags;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new ContentRepository(config.apis.article, token);
        componentInfo.value.isLoggedIn = true;
    }    
});

const fnRegist = () => {
    router.push(`/content/edit`);
};

const fnEdit = () => {
    router.push(`/content/edit/${componentInfo.value.content.id}`);
};

const onDelete = () => {
    if (componentInfo.value.content.id !== null && componentInfo.value.content.id !== undefined && String(componentInfo.value.content.id).trim() !== '') {
        MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
        let rst = await contentRep.ContentRemove(componentInfo.value.content.id);
            if (rst.check) {
                MessageBox.Success(t('system.Deleted'), () => {
                    location.href = `/content/list/${componentInfo.value.tag}?curpage=${curpage.value}`;
                });
            } else {
                MessageBox.Alert(rst.message);
            }
        });
    }
}

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["flex justify-between my-3 py-3 border-t border-gray-300 dark:border-gray-600", _unref(componentInfo).class])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(DefaultButton, {
            class: "",
            "click-event": fnRegist,
            icon: "fa-solid fa-pen-to-square",
            size: "md",
            type: "default"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('common.ButtonAdd')), 1)
            ]),
            _: 1
          }),
          (_unref(componentInfo).content.id !== null && _unref(componentInfo).content.id !== undefined && String(_unref(componentInfo).content.id).trim() !== '')
            ? (_openBlock(), _createBlock(DefaultButton, {
                key: 0,
                class: "",
                "click-event": fnEdit,
                icon: "fa-solid fa-screwdriver-wrench",
                size: "md",
                type: "default"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('common.Edit')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_unref(componentInfo).content.id !== null && _unref(componentInfo).content.id !== undefined && String(_unref(componentInfo).content.id).trim() !== '')
            ? (_openBlock(), _createBlock(DefaultButton, {
                key: 0,
                class: "",
                "click-event": onDelete,
                icon: "fa-solid fa-eraser",
                size: "md",
                type: "default"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('common.Delete')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})