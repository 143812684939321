const config = {
    mode : process.env.VUE_APP_ENV,
    baseUrl : process.env.VUE_APP_SERVICE_URL,
    identity : {
        baseUrl : process.env.VUE_APP_IDENTITY_BASE_URL,
        clientId : process.env.VUE_APP_IDENTITY_CLIENTID,
        clientSecret : process.env.VUE_APP_IDENTITY_CLIENTSECRET,
        scope : process.env.VUE_APP_IDENTITY_SCOPE,
        cookieName : process.env.VUE_APP_IDENTITY_COOKIE,
        api : process.env.VUE_APP_IDENTITY_API_URL
    },
    apis : {
        content : process.env.VUE_APP_API_CONTENT_URL,
        resource : process.env.VUE_APP_API_RESOURCE_URL,
        notification : process.env.VUE_APP_API_NOTIFICATION_URL,
        translation : process.env.VUE_APP_API_TRANSLATION_URL,
        member : process.env.VUE_APP_API_MEMBER_URL,
        site : process.env.VUE_APP_API_SITE_URL,
        article : process.env.VUE_APP_API_ARTICLE_URL,
        ai : process.env.VUE_APP_API_AI_AGENT_URL,
        board : process.env.VUE_APP_API_BOARD_URL,
        copilot : process.env.VUE_APP_API_COPILOT_URL
    },
    image: process.env.VUE_APP_API_IMAGE_URL,
    isMobile : (window.innerWidth < 768),
    global : {
        cookieName : "MerryUser",
        slug: 'merrytoktok',
        version: 4
    },
    info : {
        email: 'webmaster@gn2studio.com'
    }, 
    site : {
        title : process.env.VUE_APP_SITE_TITLE,
        key : process.env.VUE_APP_SITE_KEY
    },
    link : {
        facebook : process.env.VUE_APP_LINK_FACEBOOK,
        youtube : process.env.VUE_APP_LINK_YOUTUBE,
        blog : process.env.VUE_APP_LINK_BLOG
    },
    editor : {
        key : process.env.VUE_APP_TINYMCE_KEY
    },
    nicepay : {
        clientId : process.env.VUE_APP_NICEPAY_CLIENTID
    }
};

export default config;