import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n';
import { TranslationRepository,CookieHelper, Globalizer } from '@gn2studio/gn2.library.common';
import config from './config';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import './assets/styles/tailwind.css';
import { createHead } from '@vueuse/head'; 
import { createPinia } from 'pinia';

const storedLanguage = CookieHelper.getCookie('i18n_redirected') || 'ko'; 
const i18n = createI18n({
  legacy: false,
  locale: storedLanguage,
  messages: {},
});

const translationRep = new TranslationRepository(config.apis.translation, '', config.global.slug);

async function loadLocaleMessages(locale: string) {
    try {
        const languageData = localStorage.getItem(`language_${locale}_${config.global.version}`);
        if (languageData !== null && languageData !== undefined) {
            const decompressedData = decompressFromUTF16(languageData);
            const parsedData = JSON.parse(decompressedData);
            i18n.global.setLocaleMessage(locale, parsedData);
        } else {
            const response = await translationRep.GetTranslations(locale, config.global.version);
            i18n.global.setLocaleMessage(locale, response.data);
    
            const compressedData = compressToUTF16(JSON.stringify(response.data));
            localStorage.setItem(`language_${locale}_${config.global.version}`, compressedData);
        }
    } catch (error) {
        console.error(`Failed to load ${locale} locale messages:`, error);
    }
}

async function setupI18n() {
    const response = await translationRep.GetLanguages();
    if (response !== null && response !== undefined && response.check) {
        for (let i = 0; i < response.data.length; i++) {
            await loadLocaleMessages(response.data[i].languageCode);
            Globalizer.cleanOldTranslationData(config.global.version, response.data[i].languageCode);
        }
    }
}

router.beforeEach(async (to, from, next) => {
    if (!i18n.global.availableLocales.length) {
        await setupI18n();
    }
    next();
});


const app = createApp(App);
const head = createHead();
const pinia = createPinia();

setupI18n();

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(head);
app.mount('#app');